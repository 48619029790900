@tailwind base;
@tailwind components;
@tailwind utilities;

.btn-primary {
  @apply text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800
}

.text-input {
 @apply bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

.card {
  @apply p-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700
}

h1 {
  @apply text-2xl font-semibold text-gray-900 dark:text-white mb-2
}

h2 {
  @apply text-xl font-semibold text-gray-900 dark:text-white mb-2
}

h3 {
  @apply text-lg font-semibold text-gray-900 dark:text-white mb-2
}

h4 {
  @apply text-base font-semibold text-gray-900 dark:text-white mb-2
}

.byline {
  @apply text-sm text-gray-500 dark:text-gray-400 -mt-2 mb-2 block
}